/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
@import '~firebaseui/dist/firebaseui.css';

.component-index-outside-side{
    width: 80px;
    height: 80px;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    background-color: #c5aadb;
    position: absolute;
    margin-right: -17px;
}

.button-general{
    border: solid 1px #39cabb !important;
    border-radius: 30px !important;
    width: 150px;
    height: 44px;
    background-color: #39cabb !important;
    color: #fff !important;
    font-weight: bold;
}

.button-general:hover {
    border: solid 1px #ae28e6 !important;
    background-color: #ae28e6 !important;
}

.back-btn{
    left: 3vw;
    position: fixed;
    z-index: 1;
    background-color: #741ba1;
    color: #0bfce3;
    border-radius: 30px;
    width: 90px;
    height: 40px;
    border: solid 1px #741ba1;
    /* display: flex; */
    display: none;
    justify-content: center;
    align-items: center;
    bottom: 10px;

}

.button-general:focus,
.back-btn:focus{
    outline: unset;
}

.loading-spin-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 170px;
    position: absolute;
}

.loadingSpin{
    position: relative;
    z-index: 1000000;
    margin: auto;
    display: block;
}

.mat-progress-spinner circle,
.mat-spinner circle{
    stroke: #741ba1 !important;
}

.footer-logo-wrapper{
    position: fixed;
    bottom: 3vh;
    width: 100%;
    text-align: center;
}

.footer-logo-wrapper img {
    width: 60px;
}

.mat-flat-button.mat-warn,
.mat-raised-button.mat-warn,
.mat-fab.mat-warn,
.mat-mini-fab.mat-warn {
    background-color:#39cabb;
}
